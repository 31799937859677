import React, { Component } from "react";
import { Row, Col, Card, Divider, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ExamCard from "../../components/ExamCard";
import Loader from "../../components/Loader";
import { getAllSponsoredForms } from "../../data/features/sponsoredForms";
import ReactGA from "react-ga";

class RankResult extends Component {
  sentenceFramed = formName => {
    var result = `Rank Predictor Result for ${formName}-2020 (January)`;
    return result;
  };

  showSponsoredForms = data => {
    return data.map((item, index) => {
      return (
        <Col xs={24} md={12} lg={8} key={index}>
          <ExamCard item={item} key={index} />
        </Col>
      );
    });
  };

  getRankWithCommas = rank => {
    return rank.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  componentDidMount() {
    this.props.getAllSponsoredForms();
  }

  googleAnalyticsEvent = () => {
    ReactGA.event({
      category: "Rank",
      action: "Re-Check",
      label: this.props.rank.user.short_name
    });
  };

  render() {
    const { loading, data, user } = this.props.rank;
    const { sponsoredForms } = this.props;
    if (data) {
      var { rank } = data;
      var commaSeparatedRank = rank.toLocaleString();
    }
    if (user) {
      var { name, short_name } = user;
    }
    return loading ? (
      <Loader />
    ) : (
      <Row type="flex" justify="center">
        <Col xs={23} className="u-margin-top-small">
          <Row type="flex" justify="center" align="middle">
            <Col xs={24} md={12}>
              <Card
                className="ant-card-forms"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "180px",
                  backgroundColor: "#F4F5F7",
                  marginTop: "44px"
                }}
              >
                <div>
                  <h2 className="u-center-text">
                    {" "}
                    {`${name}, Your Probable Rank for ${short_name}-2020 (January) is : `}
                  </h2>
                  <br />
                  <p
                    style={{ fontWeight: "400", fontSize: "40px" }}
                    className="u-center-text result-primary"
                  >
                    {`${commaSeparatedRank}`}
                  </p>
                </div>
              </Card>
              <br />
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <Link to={`/${short_name}`}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.googleAnalyticsEvent}
                >
                  Recheck Your Rank
                </Button>
              </Link>
            </Col>
          </Row>
          <br />
          <br />
          <Divider />
          {sponsoredForms.loading === false ? (
            <Row>
              <Col xs={24}>
                <div>
                  <div className="u-center-text u-margin-bottom-small u-margin-top-small">
                    <h2 className="heading-secondary">
                      Explore Latest Admission Forms
                    </h2>
                  </div>
                  <div className="u-margin-bottom-medium">
                    <Row type="flex" justify="center">
                      <Col xs={24} md={20} lg={22} xl={20}>
                        <Row type="flex">
                          {this.showSponsoredForms(sponsoredForms.data)}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ rank, exams, sponsoredForms }) {
  return { rank, exams, sponsoredForms };
}
export default connect(mapStateToProps, { getAllSponsoredForms })(RankResult);
