import React from "react";
import { Tooltip, Icon, Divider } from "antd";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import { Form, Input, Select, Row, Col, Button, Card } from "antd";

import NumericInput from "../../components/NumericInput";
import { getPredictedRank } from "../../data/features/rank";
import Info from "../../components/Info";
const { Option } = Select;

class RegistrationForm extends React.Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {
          name,
          phone,
          email,
          registration_no,
          expected_percentile
        } = values;
        const data = {
          exam_short_name: "JEE MAIN",
          name,
          phone,
          email,
          registration_no,
          expected_percentile
        };
        this.props.getPredictedRank(data, this.props.history);
        this.googleAnalyticsEvent();
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  googleAnalyticsEvent = () => {
    ReactGA.event({
      category: "Rank",
      action: "Check",
      label: "JEE MAIN"
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const short_name = "JEE MAIN";

    const minimum_percentile = 0;
    const maximum_percentile = 100;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 12 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "91"
    })(
      <Select style={{ width: 70 }}>
        <Option value="91">+91</Option>
      </Select>
    );

    return (
      <Row type="flex" justify="center">
        <Col xs={23}>
          <div className="u-center-text u-margin-bottom-small u-margin-top-medium">
            <h2 className="heading-secondary">
              {`Rank Calculator for ${short_name}-2020 (January)`}
            </h2>
            <p className="lead">Enter Details below to calculate your rank</p>
          </div>
          <Row type="flex" justify="center">
            <Col xs={24} lg={12}>
              <Card
                style={{
                  marginBottom: "60px",
                  padding: "10px",
                  backgroundColor: "#F4F5F7"
                }}
                bordered={true}
              >
                <br />
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item {...formItemLayout} label="Name">
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Name!",
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Your Name" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="E-mail">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          type: "email",
                          message: "The input is not valid E-mail!"
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail!"
                        }
                      ]
                    })(<Input placeholder="Your Email Id" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="Phone No.">
                    {getFieldDecorator("phone", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your phone number!"
                        }
                      ]
                    })(
                      <Input
                        addonBefore={prefixSelector}
                        style={{ width: "100%" }}
                        type="text"
                        step={1}
                        placeholder="Your Phone No."
                        maxLength={10}
                        pattern="\d{10}"
                        title="Please enter exactly 10 digits"
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <span>
                        Registration No.
                        <em style={{ color: "#999" }}>(optional)</em>
                      </span>
                    }
                  >
                    {getFieldDecorator("registration_no", {
                      rules: [
                        {
                          required: false,
                          message: `Please enter your ${short_name} Registration no.!`
                        }
                      ]
                    })(
                      <Input
                        placeholder={`Your ${short_name} Registration No.`}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <span>
                        Percentile{" "}
                        <em
                          style={{
                            color: "rgba(0,0,0,0.45)",
                            fontStyle: "normal"
                          }}
                        >
                          <Tooltip
                            title={`Maximum percentile ${maximum_percentile}`}
                          >
                            <Icon
                              type="info-circle-o"
                              style={{ marginRight: 4 }}
                            />
                          </Tooltip>
                        </em>
                      </span>
                    }
                    extra={`Maximum percentile for an examination is ${maximum_percentile}.`}
                  >
                    {getFieldDecorator("expected_percentile", {
                      rules: [
                        {
                          required: true,
                          message: "Please Enter your Percentile!"
                        }
                      ]
                    })(
                      <NumericInput
                        minimum_percentile={minimum_percentile}
                        maximum_percentile={maximum_percentile}
                      />
                    )}
                  </Form.Item>
                  <br />
                  <Form.Item {...tailFormItemLayout}>
                    <Row>
                      <Col xs={24} sm={21}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          style={{ width: "inherit" }}
                        >
                          Calculate My Rank
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={23} className="u-margin-bottom-small">
          <Divider />
        </Col>
        <Col xs={23} className="u-margin-bottom-large">
          <Info short_name={"JEE MAIN"} />
        </Col>
      </Row>
    );
  }
}

const WrappedRegistrationForm = Form.create()(RegistrationForm);

function mapStateToProps({ exams }) {
  return { exams };
}

export default connect(mapStateToProps, { getPredictedRank })(
  WrappedRegistrationForm
);
