import React from "react";
import { enquireScreen } from "enquire-js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { PropTypes } from "prop-types";
import { Nav00DataSource, Footer10DataSource } from "./data.source";
import Nav0 from "./components/Nav0";
import Footer1 from "./components/Footer1";
import ScrollToTop from "./ScrollToTop";
import RankForm from "./pages/rank_calculator/RankForm";
import RankResult from "./pages/rank_calculator/RankResult";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

ReactGA.initialize("UA-83305228-7");

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const { isMobile } = this.state;

    return (
      <Router>
        <ScrollToTop>
          <GAListener>
            <div className="templates-wrapper">
              <Nav0
                id="Nav0_0"
                key="Nav0_0"
                dataSource={Nav00DataSource}
                isMobile={isMobile}
              />
              <Switch>
                <Route exact path="/result" component={RankResult} />
                <Route path="/" component={RankForm} />
              </Switch>
              <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={isMobile}
              />
            </div>
          </GAListener>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
