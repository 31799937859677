import React, { PureComponent } from "react";
import { Card, Skeleton } from "antd";
import { OutboundLink } from "react-ga";
const { Meta } = Card;

class ExamCard extends PureComponent {
  render() {
    const { item } = this.props;
    const { short_name, description, featured_image, url } = item;
    return (
      <OutboundLink
        eventLabel={`Lead - ${short_name}`}
        to={url}
        target="_blank">
        <Card
          style={{ margin: "20px" }}
          cover={
            <img
              alt={featured_image.alt_text}
              src={`https://storage.googleapis.com/ezap-prod${
                featured_image.url
              }`}
              className="card__bg"
            />
          }
          actions={[<p style={{ color: "white" }}>Apply Now</p>]}
          hoverable
          bordered={true}>
          <Skeleton loading={false} active>
            <Meta
              title={short_name}
              description={description}
              style={{ height: "150px" }}
            />
          </Skeleton>
        </Card>
      </OutboundLink>
    );
  }
}

export default ExamCard;
