import api from "../utils/api";

// =================================================================================================
// EXAM TYPES ======================================================================================
// =================================================================================================
const EXAM_RANK_CALCULATOR_LOADING = "EXAM_RANK_CALCULATOR_LOADING";
const EXAM_RANK_CALCULATOR_SUCCESS = "EXAM_RANK_CALCULATOR_SUCCESS";
const EXAM_RANK_CALCULATOR_ERROR = "EXAM_RANK_CALCULATOR_ERROR";

// =================================================================================================
// EXAM REDUCERS ===================================================================================
// =================================================================================================
export default function reducer(state = { loading: true }, action) {
  switch (action.type) {
    case EXAM_RANK_CALCULATOR_LOADING:
      return { ...state, loading: true, user: action.user };
    case EXAM_RANK_CALCULATOR_SUCCESS:
      return { ...state, loading: false, data: action.data, error: null };
    case EXAM_RANK_CALCULATOR_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

// =================================================================================================
// EXAM ACTIONS ====================================================================================
// =================================================================================================
export const getPredictedRank = (data, history) => async dispatch => {
  history.push("/result");
  dispatch({
    type: EXAM_RANK_CALCULATOR_LOADING,
    user: { name: data.name, short_name: data.exam_short_name }
  });
  try {
    const res = await api.post(api.endpoints.rank, data);
    dispatch({ type: EXAM_RANK_CALCULATOR_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: EXAM_RANK_CALCULATOR_ERROR, error: error.data.message });
  }
};
