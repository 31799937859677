import React, { Component } from "react";
import { Spin } from "antd";

class Loader extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          height: "100vh",
          backgroundColor: "#d8e6df"
        }}>
        <Spin style={{ marginTop: "50vh" }} />
      </div>
    );
  }
}

export default Loader;
