import axios from "axios";
import endpoints from "./apiConstants";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://ezap.entrancezone.com";
const instance = axios.create({
  baseURL,
  timeout: 60000
});

const request = (method, url, data, headers) => {
  return new Promise((resolve, reject) => {
    (() => {
      if (process.env.NODE_ENV === "development") {
        instance.defaults.withCredentials = true;
      }
      if (method === "get") {
        return instance.request({
          url,
          method,
          params: data,
          headers
        });
      } else {
        return instance.request({
          url,
          method,
          data,
          headers
        });
      }
    })()
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export default {
  get: (endpoint, data, headers = {}) => {
    return request("get", endpoint, data, headers);
  },
  post: (endpoint, data, headers = {}) => {
    return request("post", endpoint, data, headers);
  },
  put: (endpoint, data, headers = {}) => {
    return request("put", endpoint, data, headers);
  },
  patch: (endpoint, data, headers = {}) => {
    return request("patch", endpoint, data, headers);
  },
  delete: (endpoint, data, headers = {}) => {
    return request("delete", endpoint, data, headers);
  },
  endpoints
};
