import api from "../utils/api";

// =================================================================================================
// FILTER TYPES ====================================================================================
// =================================================================================================
const SPONSORED_FORMS_LOADING = "SPONSORED_FORMS_LOADING";
const SPONSORED_FORMS_SUCCESS = "SPONSORED_FORMS_SUCCESS";
const SPONSORED_FORMS_ERROR = "SPONSORED_FORMS_ERROR";

// =================================================================================================
// FILTER REDUCERS =================================================================================
// =================================================================================================
export default function reducer(state = { loading: true }, action) {
  switch (action.type) {
    case SPONSORED_FORMS_LOADING:
      return { ...state, loading: true };
    case SPONSORED_FORMS_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case SPONSORED_FORMS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

// =================================================================================================
// FILTER ACTIONS ==================================================================================
// =================================================================================================
export const getAllSponsoredForms = () => async dispatch => {
  dispatch({ type: SPONSORED_FORMS_LOADING });
  try {
    const res = await api.get(api.endpoints.sponsored_forms);
    dispatch({ type: SPONSORED_FORMS_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: SPONSORED_FORMS_ERROR, error: error.data.message });
  }
};
