import React from "react";
import { Card } from "antd";
import MathJax from "react-mathjax";

const tex = `rank = \\ \\frac{(100-percentile)*8,69,010}{100}\\`;

const Info = ({ short_name }) => {
  return (
    <Card className="info" title={`${short_name}-2020 Rank Calculator`}>
      <h3>How to calculate your rank from your NTA percentile?</h3> <br />
      <p>
        The Result for the Paper-I of JEE Mains which was conducted in January
        2020 has been declared two weeks prior than the expected date by NTA on
        their website. Students are confused with result declared by NTA because
        it does not show either the raw (actual) marks obtained or the
        percentage of their performance. Instead the scores are in the form of
        percentile. This has created a lot of confusion and students are banking
        their hopes on determining their JEE Mains rank for Paper-I.
      </p>
      <br />
      <p>
        The Joint Entrance Examination Mains shortly called JEE Mains is one the
        most prestigious examination conducted in the country for B.E./BTech or
        B.Arch./B.Planning aspirants which was held once an academic year. But
        following this year the examination would be conducted twice a year i.e.
        once in January and the other in April. The JEE mains will now be
        conducted in collaboration with the National Testing Agency (NTA).
      </p>
      <br />
      <p>
        The first paper which has already been conducted between 6th to 9th
        January 2020. The result for the very same was declared by NTA on their
        website on 17th January 2020. The NTA score which were generated for the
        candidates were not the percentage of the marks they scored but were the
        percentile i.e. the score indicates the percentage of the students who
        scored equal or below that particular percentile in that session.
      </p>
      <br />
      <p>
        Now in order to calculate your rank we need to know some figures and
        understand some facts. As mentioned above Paper-I was conducted for four
        days with two sessions per day. That gives a total of eight sessions.
        Now to understand how to calculate the rank first we need to know how
        many candidates scored above you which can calculated by subtracting
        your score from 100 which is the highest score.
      </p>
      <br />
      <p>
        The scoring done by NTA is from 0 which is lowest to 100 which is
        highest. As a total of 8,69,010 students appeared for the examination.
        So the final formula to calculate your JEE Mains 2020 January rank will
        be as follows
      </p>
      <p className="steps-content">
        <MathJax.Provider>
          <MathJax.Node formula={tex} />
        </MathJax.Provider>
      </p>
      <br />
      <p>
        Even if you think you were not able to perform up to your level best in
        the January examination there is no need to get worried or tensed. You
        have got another chance within this year to appear for the second JEE
        Mains examination to be conducted in April. Learn from your mistakes
        this time and work on them.
      </p>
      <br />
      <p>
        Further details about the Paper-II to be conduced in April regarding the
        application form, final dates of submission etc can found on NTA
        website.
      </p>
    </Card>
  );
};

export default Info;
