import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";

import sponsoredForms from "./features/sponsoredForms";
import rank from "./features/rank";
import user from "./user";

const rootReducer = combineReducers({
  sponsoredForms,
  user,
  rank
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
