// =================================================================================================
// USER TYPES ======================================================================================
// =================================================================================================
const USER_UPDATE = "USER_UPDATE";

// =================================================================================================
// FILTER REDUCERS =================================================================================
// =================================================================================================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATE:
      return action.data;
    default:
      return { ...state };
  }
}

// =================================================================================================
// USER ACTIONS ====================================================================================
// =================================================================================================
export const updateUserDetail = data => async dispatch => {
  dispatch({ type: USER_UPDATE, data });
};
